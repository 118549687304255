import React from 'react';

import { IconProps } from '../IconRoot';

export const Keyboard: React.FC<IconProps> = (props) => (
    <svg width="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.25 5c.966 0 1.75.784 1.75 1.75v10.5A1.75 1.75 0 0120.25 19H3.75A1.75 1.75 0 012 17.25V6.75C2 5.784 2.784 5 3.75 5h16.5zm-1.125 10H4.875a.875.875 0 00-.875.875v.25c0 .483.392.875.875.875h14.25a.875.875 0 00.875-.875v-.25a.875.875 0 00-.875-.875zm-11-4h-.25a.875.875 0 00-.875.875v.25c0 .483.392.875.875.875h.25A.875.875 0 009 12.125v-.25A.875.875 0 008.125 11zm4 0h-.25a.875.875 0 00-.875.875v.25c0 .483.392.875.875.875h.25a.875.875 0 00.875-.875v-.25a.875.875 0 00-.875-.875zm4 0h-.25a.875.875 0 00-.875.875v.25c0 .483.392.875.875.875h.25a.875.875 0 00.875-.875v-.25a.875.875 0 00-.875-.875zm2-3h-.25a.875.875 0 00-.875.875v.25c0 .483.392.875.875.875h.25A.875.875 0 0019 9.125v-.25A.875.875 0 0018.125 8zm-4 0h-.25a.875.875 0 00-.875.875v.25c0 .483.392.875.875.875h.25A.875.875 0 0015 9.125v-.25A.875.875 0 0014.125 8zm-4 0h-.25A.875.875 0 009 8.875v.25c0 .483.392.875.875.875h.25A.875.875 0 0011 9.125v-.25A.875.875 0 0010.125 8zm-4 0h-.25A.875.875 0 005 8.875v.25c0 .483.392.875.875.875h.25A.875.875 0 007 9.125v-.25A.875.875 0 006.125 8z"
            fill="currentColor"
        />
    </svg>
);
